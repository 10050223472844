import { Category } from '@eo-storefronts/eo-core'
import { Alert, Box } from '@mui/material'
import { useCategoryAvailabilityMessage } from '~/src/hooks/categories/useCategoryAvailabilityMessage'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

interface Props {
  category: Category,
}

const CategoryNotAvailableMessage = ({ category }: Props) => {
  const muiTheme = useCustomMuiTheme()
  const { message: availabilityMessage } = useCategoryAvailabilityMessage(category.id)

  return (
    <Box sx={{
      pb: 4,
      [muiTheme.breakpoints.down('md')]: {
        px: 2,
        pb: 3
      }
    }}>
      <Alert
        sx={{ alignItems: 'center' }}
        severity='info'
      >
        {availabilityMessage}
      </Alert>
    </Box>
  )
}

export default CategoryNotAvailableMessage
