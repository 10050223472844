import { SxProps } from '@mui/material'
import StylesCategoryLabelServiceInterface
  from '~/src/components/category/category-label/styles/StylesCategoryLabelServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesCategoryLabelServiceStyleTwo extends TemplateServiceStyleBase implements StylesCategoryLabelServiceInterface {
  public getPageSx(): SxProps {
    return {}
  }

  public getTitleSx(): SxProps {
    return {
      fontWeight: 600,
      py: 4,
      color: 'background.contrastText',
      [this.muiTheme.breakpoints.down('md')]: {
        pt: 2,
        pl: 2
      }
    }
  }
}
