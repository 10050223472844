import { Category } from '@eo-storefronts/eo-core'
import useGetCategoryLabelTemplate from '~/src/components/category/category-label/styles/useGetCategoryLabelTemplate'
import Title from '~/src/components/mui-wrappers/Title'
import { useEoValue } from '~/src/hooks/useEoState'
import { LANGUAGE_STATE } from '~/src/stores/lang'

interface Props {
  category: Category,
}

const CategoryLabel = ({ category }: Props) => {
  const styles = useGetCategoryLabelTemplate()
  const lang = useEoValue(LANGUAGE_STATE)

  return (
    <Title
      variant="h5"
      className="eo-ellipsis"
      sx={styles?.getTitleSx()}
    >
      {category.name[lang]}
    </Title>
  )
}

export default CategoryLabel
