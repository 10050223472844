import { Id } from '@eo-storefronts/eo-core'
import CategoryLabel from '~/src/components/category/category-label'
import CategoryNotAvailableMessage from '~/src/components/category/CategoryNotAvailableMessage'
import ProductList from '~/src/components/products/product-list'
import { useCategoryAvailable } from '~/src/hooks/categories/useCategoryAvailable'
import { Box } from '@mui/material'
import { useEoValue } from '~/src/hooks/useEoState'
import { LANGUAGE_STATE } from '~/src/stores/lang'
import { CATEGORY_BY_ID_SELECTOR_FAMILY } from '~/src/stores/categories'

interface Props {
  categoryId: string,
}

const CategoryDetail = ({ categoryId }: Props) => {
  const category = useEoValue(CATEGORY_BY_ID_SELECTOR_FAMILY(categoryId))
  const lang = useEoValue(LANGUAGE_STATE)
  const { available } = useCategoryAvailable(categoryId)

  if (!category || !category.products.length && !category.subcategories.length) {
    return null
  }

  return (
    <Box
      component='section'
      id={encodeURIComponent(category.name[lang] || '')}>
      {category.subcategories.map((subcategoryId: Id) => (
        <CategoryDetail
          categoryId={subcategoryId.toString()}
          key={subcategoryId}/>
      ))}

      {!!category.products?.length && (
        <>
          <CategoryLabel category={category}/>
          {!available && <CategoryNotAvailableMessage category={category}/>}
          <ProductList productsId={category.products}/>
        </>
      )}
    </Box>
  )
}

export default CategoryDetail
