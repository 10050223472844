import StylesCategoryLabelServiceInterface
  from '~/src/components/category/category-label/styles/StylesCategoryLabelServiceInterface'
import StylesCategoryLabelServiceStyleFour
  from '~/src/components/category/category-label/styles/StylesCategoryLabelServiceStyleFour'
import StylesCategoryLabelServiceStyleOne
  from '~/src/components/category/category-label/styles/StylesCategoryLabelServiceStyleOne'
import StylesCategoryLabelServiceStyleTwo
  from '~/src/components/category/category-label/styles/StylesCategoryLabelServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum, Theme } from '@eo-storefronts/eo-core'

const getCategoryLabelTemplateService = (style: TemplateEnum | null | undefined, muiTheme: CustomMuiTheme, firmTheme?: Theme): StylesCategoryLabelServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesCategoryLabelServiceStyleTwo(muiTheme, firmTheme)
    case TemplateEnum.STYLE_FOUR:
      return new StylesCategoryLabelServiceStyleFour(muiTheme, firmTheme)
    default:
      return new StylesCategoryLabelServiceStyleOne(muiTheme, firmTheme)
  }
}

export default getCategoryLabelTemplateService
